@import 'root.scss';
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;1,100;1,300&display=swap");
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";

@import 'animate.css';


:root {

    --primary-color: #0158FF;
    --warn-color: #ff0e01;
    --primary-color-3: #4484ff;

    --secondary-color: black;
    --secondary-color-2: #3d3d3d;


}

*::-webkit-scrollbar {
    width: 6px;
    height: 8px;
}

*::-webkit-scrollbar-track {
    background: white;
    border: 1px solid rgb(196, 196, 196);
}

*::-webkit-scrollbar-thumb {
    background-color: var(--primary-color);
    border-radius: 10px;
}

.txt-primary {
    color: var(--primary-color);
}

* {
    margin: 0;
    padding: 0;
    font-family:
        Arial,
        "Segoe UI",
        Roboto,
        Helvetica,
        sans-serif,
        "Apple Color Emoji",
        "Segoe UI Emoji",
        "Segoe UI Symbol"
}



body {
    height: 100%;

    h1 {}

}

@media screen and (max-width: 768px) {

    html,
    body {

        font-size: 90%;
    }
}